import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';

import { ButtonGroup, FormControl, Overlay, Popover } from 'react-bootstrap';

import { dt } from '@core/utils/Environment';
import { getBaseUrl, getUrlFromInvite } from '@core/utils/Generators';
import { isEmail } from '@core/utils/Validation';

import { Button, ButtonToolbar, Form, Loader } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

const defaultMessage = (to, from, dealTeamName) => {
  return `Hello ${to ? _.get(to, 'fullName', to.email) : ''},
Please review the following agreement${dealTeamName ? ' with ' + dealTeamName : ''}.
Don't hesitate to contact me if you have any questions.
Thank you,
${from ? _.get(from, 'fullName', from.email) : ''}`;
};

const SHARE_TYPES = [
  { key: 'email', title: 'Share via email', icon: 'email' },
  { key: 'link', title: 'Share via link', icon: 'link' },
];

@autoBindMethods
export default class SendDeal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      target: null,
      container: null,
      placement: null,
      email: '',
      message: '',
      sending: false,
      error: '',
      mode: null,
      url: '',
      copied: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.populate(props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  populate(props) {
    const du = props.du;
    const me = props.deal ? props.deal.currentDealUser : null;
    const message = this.state.message;
    const dealTeamName = props.deal.info.legalName;
    //if a user edits a team members name or email and goes to share the contract, make sure the changes are reflected in the message.
    const updatedMessage = defaultMessage(du, me, dealTeamName);

    this.setState({
      email: du ? du.email || '' : '',
      message: du && me && updatedMessage !== message ? updatedMessage : message,
    });
  }

  inviteUser(du) {
    const { deal, user } = this.props;
    const { mode } = this.state;
    const email = this.state.email.trim();
    const message = this.state.message.trim();
    const linkOnly = mode === 'link';

    if (isEmail(email) || mode === 'link') {
      this.setState({ sending: true, error: '' });

      const sendInvite = (json) => {
        API.call(
          'invite',
          { dealID: deal.dealID, inviter: user, email, message, du: json, linkOnly },
          (invite) => {
            if (mode === 'email') {
              // Close after sending
              if (this._isMounted) this.setState({ show: false, sending: false, message: '', error: '' });
            } else if (mode === 'link') {
              const url = getUrlFromInvite(invite, getBaseUrl());
              this.setState({ url, sending: false, error: '' });
            }
          },
          ({ error }) => {
            // console.log(error);
            this.setState({ sending: false, error });
          }
        );
      };

      // du will be null if direct sharing to new user (e.g., "Share with counsel" link)
      // if so, all we need for API invite call is a temporary key
      if (du) {
        sendInvite(du.json());
      } else {
        Fire.createDealUser(deal, {}, sendInvite);
      }
    } else {
      this.setState({ error: 'Invalid email address.' });
    }
  }

  focus() {
    const el = ReactDOM.findDOMNode(this.refs.email);
    if (el) el.focus();
  }

  copyURL() {
    const txt = ReactDOM.findDOMNode(this.refs.url);
    if (txt) {
      txt.select();
      document.execCommand('copy');
      this.setState({ copied: true });
      setTimeout(() => {
        if (this._isMounted) this.setState({ copied: false });
      }, 1000);
    }
  }

  show(target, container, placement) {
    this.setState({ show: true, target, container, placement });
  }

  hide() {
    this.setState({ show: false, url: '' });
  }

  render() {
    const { du } = this.props;
    const { mode, email, error, sending, message, url, copied, show, target, container } = this.state;
    const placement = this.state.placement || this.props.placement || 'right';
    const validEmail = isEmail(email.trim());

    return (
      <Overlay
        container={container}
        onEntered={this.focus}
        onHide={() => this.setState({ show: false })}
        placement={placement}
        shouldUpdatePosition
        show={show}
        target={target}
      >
        <Popover
          className="popover-send-deal"
          id={`pop-send-${du ? du.key : 'new-du'}`}
          ref="sendDeal"
          title={`Share ${dt}${du ? ' with ' + du.firstName : ''}`}
          data-cy="popover-send-deal"
        >
          <img src="/assets/svg/close-dark-tiny.svg" className="close-popover" onClick={this.hide} alt="close" />

          <Form>
            <ButtonGroup className="share-type">
              {SHARE_TYPES.map((t) => (
                <Button
                  active={mode === t.key}
                  disabled={sending}
                  key={t.key}
                  onClick={() => this.setState({ mode: t.key })}
                  icon={t.icon}
                  size="small"
                  data-cy={`share-via-${t.key}`}
                >
                  {t.title}
                </Button>
              ))}
            </ButtonGroup>

            {mode == 'email' && (
              <FormControl
                className="email"
                disabled={sending}
                onChange={(e) => this.setState({ email: e.target.value })}
                onKeyDown={(e) => (e.key == 'Enter' ? this.inviteUser(du) : null)}
                placeholder="Enter email address"
                ref="email"
                type="text"
                value={email}
                bsSize="small"
                data-cy="input-share-email"
              />
            )}

            {mode == 'email' && (
              <FormControl
                className="custom-message"
                componentClass="textarea"
                disabled={sending}
                onChange={(e) => this.setState({ message: e.target.value })}
                placeholder="Enter custom message (optional)"
                value={message}
                bsSize="small"
                data-cy="custom-message"
              />
            )}

            {mode == 'link' && (
              <FormControl
                className={cx('url', { copied })}
                onClick={(e) => e.target.setSelectionRange(0, e.target.value.length)}
                placeholder="Tap 'Get Link' for private access URL"
                readOnly
                ref="url"
                type="text"
                value={url}
                bsSize="small"
                data-cy="private-access-link"
              />
            )}
            {copied && <span className="copied">COPIED</span>}

            {mode && error && <small className="text-danger">{error}</small>}
            {mode && (
              <ButtonToolbar align="between">
                <ButtonToolbar.Group>
                  {sending && <Loader />}
                  {mode == 'link' && !!url && (
                    <Button dmpStyle="link" size="small" noPadding onClick={this.copyURL} data-cy="btn-copy-link">
                      Copy link
                    </Button>
                  )}
                </ButtonToolbar.Group>
                <ButtonToolbar.Group>
                  <Button
                    disabled={sending}
                    size="small"
                    onClick={this.hide}
                    data-cy={`btn-${!!url ? 'Done' : 'Cancel'}-direct-share`}
                  >
                    {mode == 'link' && !!url ? 'Done' : 'Cancel'}
                  </Button>
                  {mode == 'email' && (
                    <Button
                      disabled={!validEmail || sending}
                      dmpStyle="primary"
                      size="small"
                      className={`share ${du ? du.inviteStatus : ''}`}
                      onClick={() => this.inviteUser(du)}
                      data-cy="btn-share"
                    >
                      Share
                    </Button>
                  )}
                  {mode == 'link' && (
                    <Button
                      dmpStyle="primary"
                      size="small"
                      disabled={sending || url != ''}
                      onClick={() => this.inviteUser(du)}
                      data-cy="btn-get-link"
                    >
                      Get Link
                    </Button>
                  )}
                </ButtonToolbar.Group>
              </ButtonToolbar>
            )}
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
