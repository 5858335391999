import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import jump from 'jump.js';
import _ from 'lodash';
import qs from 'query-string';

import { Redirect, Route } from 'react-router-dom';

import Model from '@core/models/Model';
import StripeCustomer from '@core/models/StripeCustomer';
import User from '@core/models/User';
import { isVine } from '@core/utils/Environment';
import LS from '@core/utils/LS';
import Stopwatch from '@core/utils/Stopwatch';

import { Button } from '@components/dmp';

import Preloader from '@components/Preloader';
import TopBar from '@components/TopBar';
import API from '@root/ApiClient';
import Auth, { PROVIDERS } from '@root/Auth';
import CRM from '@root/CRM';
import CONFIG from '@root/Config';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';
import { firebaseAuth } from '@root/outlaw';
import ErrorView from '@root/routes/ErrorView';
import Routes from '@routes/Routes';

@autoBindMethods
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anonEmail: null,
      dark: false,
      loginError: null,
      user: undefined, // Outlaw user profile info
      account: undefined, // Underlying Firebase account
      subscription: null, // Subscription status return from checkSubscription API call
      updatingAuth: false, // Used to block app rendering during auth transitions and account linking/migration
      teams: undefined, // Global app state for user's team membership so we don't have to keep reloading
      team: null, // Global currently selected team, used throughout app
      editingTeam: false, // Whether user is currently editing a team
      creatingTeam: false, // Whether user is currently creating a new team
      mobile: Dealer.mobile,

      // When running in (iframe) mode, this stores white-label contextual data shared with us by the parent frame/app
      // currently limited to Filevine but intended to be extensible to other enterprise white-label scenarios
      wlContext: null,

      checkSubscription: this.checkSubscription,
      authChange: this.authChange,
      getTeams: this.getTeams,
      selectTeam: this.selectTeam,
      setDarkMode: (dark) => this.setState({ dark }),
      toggleTeamCreation: (creatingTeam) => this.setState({ creatingTeam }),
      scrollToTop: (animate) => jump('body', { duration: animate ? 500 : 0 }),
    };

    this.sw = new Stopwatch('APP');
    this.swVine = new Stopwatch('Vine');

    //listen for browser resizing and force rerender via state
    this.resizeListener = _.debounce(() => {
      this.setState({ appWidth: window.innerWidth, appHeight: window.innerHeight, mobile: Dealer.mobile });
    }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  // This is the listener for window.postMessage from Filevine, when integrated inside iframe
  // After sending the ready event (see render()) to parent,
  // parent should respond with dotvine event, sending contextual information
  // both of these things need to happen before we can safely complete the loading sequence
  async messageListener(event) {
    const { origin, data } = event;

    if (data && data.messageType) this.swVine.step(`Received message from parent. ${JSON.stringify({ origin, data })}`);

    // Ensure that sender origin to be trusted.
    if (!/\.filevine(dev|app|gov)?\.(com|ca)(:[\d]+)?$/.test(origin)) {
      if (data && data.messageType) this.swVine.step(`origin "${origin}" is not allowed, rejecting message.`);
      return;
    }

    const message = data || {};

    switch (message.messageType) {
      case 'dotvine':
        this.swVine.step(`Received "dotvine" message from parent -- storing white-label context in app.`);

        // Compare Filevine user to Outlaw user by email; if different, logout Outlaw user!
        const { user } = this.state;
        const outlawUser = _.get(user, 'email', '');

        const wlUser = _.get(message.data, 'user');
        const wlName = _.get(message.data, 'fullName');
        const vineID = _.get(message.data, 'vineID');

        await this.setState({
          wlContext: {
            wlUser,
            wlName,
            vineID,
          },
        });

        if (wlUser && wlUser.toLowerCase() !== outlawUser.toLowerCase()) {
          if (!!outlawUser) Auth.logout();
          this.props.history.replace(`/wllogin/?redirect=${encodeURIComponent(window.location.pathname)}`);
        }

        break;
      // Additional cases can be added here for other cross-app comms

      default:
        this.swVine.step(`Unhandled "${message.messageType}" message type -- do nothing.`);
        break;
    }
  }

  componentDidMount() {
    this.sw.step('App component mounted');
    this.sw.step(`isVine: ${isVine}`);
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);
    window.addEventListener('message', this.messageListener);

    document.cookie = `instance=${CONFIG?.INSTANCE ?? 'app'};path=/;domain=.getoutlaw.com;`;

    Auth.init(firebaseAuth, this.state.authChange, this.authChangeInProgress);

    // CASE 1 -- Existing Account
    // user is not currently logged in, and has attempted to login with a different provider
    // but provider has email address that matches an existing account
    // we need to warn user and instruct them to login via existing provider, then link accounts
    const diffCred = (currentProviders, pendingCred) => {
      const other = _.find(PROVIDERS, { id: currentProviders[0] }); // only require to return one of the current providers.
      const pending = _.find(PROVIDERS, { id: pendingCred.providerId });
      let loginError = `You already created an Outlaw account. Sign in with it again and then link your ${pending.name} login in your Profile.`;
      if (other) {
        loginError = `You already created an Outlaw account through ${other.name}. Sign in with ${other.name} again and then link your ${pending.name} login in your Profile.`;
      }
      this.setState({ loginError });
    };

    // CASE 2 -- Anonymous Migration
    // User is already authed anonymously (e.g., from guest login) and then logs into an EXISTING account
    // NOTE: this will only happen if the account already existed.
    // If when logging in the user establishes a NEW account (new email),
    // none of this will fire as the anonymous account will simply become the auth'd one (same uid)
    const mergeAnonymous = async (anonUser, pendingCred) => {
      await this.setState({ updatingAuth: true });

      // We're initially still authenticated as an anonymous user
      // so we need to grab current auth token for use in account merge call
      // before we proceed with signing in with the supplied credentials
      const fromToken = await Fire.token();

      // Remove potential Fire listener on user account so that we don't get a security error
      // when we login with the new credential (next line)
      this.unwatchProfile(anonUser);

      try {
        // Move forward with signing in with the (existing) account the user just auth'd
        await Auth.login(null, pendingCred);

        // Now we've got both the old guest token and the fully logged in account
        // Head over to /mergeAccounts for safe merging (i.e., while not looking at any data),
        // which will then come back to the current route when done
        const { location, history } = this.props;
        const params = qs.parse(location.search);
        let url = `/mergeAccounts?fromToken=${fromToken}`;
        if (params.redirect) {
          url += `&redirect=${encodeURIComponent(params.redirect)}`;
        }

        history.push(url);
        await this.setState({ updatingAuth: false });
      } catch (err) {
        //handle error so that we don't end up with infinite spinner
        console.log(err);
        await this.setState({ updatingAuth: false });
      }
    };

    // CASE 3 -- Linking Providers
    // here and we have both a pending credential AND a currently logged in user
    // but the providers are different, so the user is linking accounts
    const linkAccounts = (currentUser, pendingCred) => {
      if (currentUser) {
        this.setState({ updatingAuth: true });
        currentUser.linkWithCredential(pendingCred).then(
          () => {
            console.log(`[AUTH] Account successfully linked to [${pendingCred.providerId}]`);
            this.setState({ updatingAuth: false });
          },
          (err) => {
            console.log(`[AUTH] Error linking account to [${pendingCred.providerId}]`, err);
            this.setState({ updatingAuth: false });
          }
        );
      }
    };
    Auth.handleLoginRedirect({ diffCred, mergeAnonymous, linkAccounts });
  }

  unwatchProfile(account) {
    Fire.db.ref(`users/${account.uid}`).off();
  }

  // Establish an always-on listener for user profile data
  // which will fire anytime any piece changes (e.g., profile info, team membership, etc)
  // This is intentionally separate from other "first-time-only" aspects of initial auth (see authChange)
  async watchProfile(account) {
    let completeCheck = false;

    return new Promise((resolve) => {
      Fire.getUser(account.uid, true, async (json) => {
        this.sw.step(`Got profile info for user [${account.uid}]`);

        const user = new User(json);

        if (!account.isAnonymous && !completeCheck) {
          await Fire.ensureProfileCompleteness(user, account);
          this.sw.step('Finished profile completeness check');
          completeCheck = true;
        }

        // TODO: only one of these should exist eventually;
        // Either update app state and pass down, or use mobx to have models watching each other
        Model.user = user;
        this.setState({ user });
        resolve(user);
      });
    });
  }

  async authChangeInProgress() {
    await this.setState({ updatingAuth: true });
  }

  async authChange(account, anonEmail) {
    const { user: currentUser } = this.state;
    const { location, history } = this.props;

    this.sw.step(`Auth change with user [${_.get(account, 'uid', 'null')}] [${_.get(account, 'email', 'null')}]`);

    // This will cause pre-loader to show
    // Don't allow any routes to render until we're done app bootstrap
    await this.setState({ updatingAuth: true });

    // If we had a previous user logged in, immediately stop watching that user's profile
    if (currentUser) this.unwatchProfile({ uid: currentUser.id });

    // Load user profile here, which also establishes the live listener
    // But using await here instead of callback will cause this to only fire once (which is what we want)
    const user = account ? await this.watchProfile(account) : null;

    if (user) {
      // When user is first logged in, we query the server to get the IP address
      // we do it here because the same user may have different IP addresses (logged in on different devices / networks)
      const clientIP = await API.call('getClientIP');

      this.sw.step(`Got user's IP [${clientIP}]`);

      // Once we get an actual IP address we fetch the location of the user
      if (clientIP) {
        user.ip = clientIP;
        let geoLocationData;
        try {
          geoLocationData = await API.call('lookupGeoIP');
          if (!geoLocationData) {
            throw new Error('lookupGeoIP failed');
          }

          user.location = `${geoLocationData.city}, ${geoLocationData.country_name}`;
          this.sw.step(`Got user's location [${user.location}]`);
        } catch (err) {
          this.sw.step(`Unable to determine user's location from [${clientIP}]`);
        }
      }

      user.userAgent = _.get(window, 'navigator.userAgent', null);
    }

    if (window.Sentry) {
      // Identify the user in Sentry
      window.Sentry.configureScope((scope) => {
        let userScope = {};
        if (user) {
          userScope = {
            email: user.email,
            id: user.id,
            username: _.get(user, 'info.fullName'),
          };
        }
        scope.setUser(userScope);
      });
    }

    //if we have a real (normal) non-anonymous user, run additional checks:
    //push latest CRM data to CRM, confirm subscription, etc
    if (user && account && !account.isAnonymous) {
      CRM.login(account, user);
      if (user.teams) {
        const teamIDs = [];
        _.forEach(user.teams, (_role, teamID) => teamIDs.push(teamID));
        //attribute lengths limited to 255 chars
      }

      const subscription = await this.checkSubscription();
      this.sw.step(`Subscription checked: [${subscription.status}]`);
      await this.getTeams(user);

      this.sw.step(`Loaded user's teams (${_.keys(user.teams).length})`);
    }

    // Finally, we're done app bootstrap; update state to re-render app with user/account
    this.sw.step(`Auth check complete; proceeding with app rendering`);
    await this.setState({ user, account, anonEmail, updatingAuth: false });

    // If user WAS logged in but has logged out, redirect to login with former path captured
    if (currentUser && !account) {
      console.log(`[AUTH] Formerly authed user logged out from [${location.pathname}]; redirecting to login`);
      // Clear all LocalStorage properties used in app
      LS.clear();
      // Redirect to login route
      if (isVine) {
        history.replace(`/wllogin/?redirect=${encodeURIComponent(location.pathname)}`);
      } else {
        history.replace(`/login/?redirect=${encodeURIComponent(location.pathname)}`);
      }
    }

    return Promise.resolve();
  }

  async checkSubscription() {
    const subscription = await API.call('checkSubscription');
    //type customer property if we get it so we don't need to load it anywhere else in app
    if (subscription.customer) subscription.customer = new StripeCustomer(subscription.customer);
    await this.setState({ subscription });
    return Promise.resolve(subscription);
  }

  async getTeams(user, selectTeamID) {
    let team = null;

    const teams = await Fire.getUserTeams(user.id);

    await this.setState({ teams: teams || [] });

    if (selectTeamID) team = _.find(teams, { teamID: selectTeamID });

    // If no team is selected yet, try to auto-select one.
    let lsTeam = LS.getTeam();

    // If we have a selected team in LocalStorage and it's in the list, select that
    if (!team && lsTeam) team = _.find(teams, { teamID: lsTeam });

    // If that doesn't yield a team, choose first in list
    if (!team && teams.length) team = teams[0];

    // If there is a team selected in state but it's been deleted (not in list) auto-select another
    if (team && !_.find(teams, { teamID: team.teamID }) && teams.length) team = teams[0];

    // Finally select that team (which can be null)
    await this.selectTeam(team);
  }

  async selectTeam(team, editingTeam = false) {
    // Set app state and persist last selected team to LocalStorage
    LS.setTeam(_.get(team, 'teamID'));
    return this.setState({ team, editingTeam });
  }

  //copied from Libre.Client
  og(tags) {
    if (typeof document === 'object') {
      if (tags.title) document.title = tags.title;
      if (tags.description) {
        const meta = document.querySelector('meta[name="description"]');
        if (meta != null) meta.setAttribute('content', tags.description);
      }
    }
  }

  renderHelpBtn() {
    if (Dealer.mobile || !CONFIG.INSTANCE || !CONFIG.HELP_URL) {
      return null;
    }

    return (
      <Button className="btn-help" dmpStyle="primary" href={CONFIG.HELP_URL} icon="questionmark" target="_blank" />
    );
  }

  render() {
    const { user, updatingAuth, wlContext } = this.state;
    const { location } = this.props;

    // This is for first initial render -- app loads before authentication is checked
    // This is necessary to avoid redirecting to /login when user is auth'd but hasn't been checked yet
    // Markup is an exact copy of server-rendered page (move to Preloader component?)
    // so user should not see any change as app bootstraps and auth/registration is happening
    const preloader = <Preloader hideLogo={isVine} />;
    if (user === undefined || updatingAuth) return preloader;

    // Additional special case; if we're in an iframe inside Filevine,
    // and the user logged into Filevine is different from the user logged into Outlaw
    // Automatically logout of Outlaw so that user can re-auth (or signup) with matching email
    if (isVine && typeof window === 'object' && !wlContext) {
      // Now we're ready for wlContext; notify parent window to ask for it!
      this.swVine.step('Sending "ready" message to parent.');
      window.parent.postMessage({ messageType: 'ready', data: null }, '*');

      // For now though, we haven't received it yet so continue to just show preloader
      return preloader;
    }

    const path = location.pathname.toLowerCase();
    const pathClass = `path-${path.split('/').pop() || 'home'}`;
    const appState = _.extend({ og: this.og }, this.state, this.props);

    return <div className={pathClass}>{Object.keys(Routes).map((path) => this.renderRoute(path, appState))}</div>;
  }

  renderRoute(key, appState) {
    const { user } = this.state;
    const routeConfig = Routes[key];
    const isPrivate = !routeConfig.anon;
    const routeProps = {
      exact: key === '/',
      key,
      main: routeConfig.main,
      path: key,
    };
    const authed = this.state.user != null;
    const Comp = routeConfig.component;

    //this is only called when the route matches!
    const render = (r) => {
      //redirect private routes to login if user is not authed at all
      if (isPrivate && !authed) {
        console.log('[Route] Redirect => isPrivate:true authed:false');
        return <Redirect to={{ pathname: `/login/?redirect=${encodeURIComponent(r.location.pathname)}` }} />;
      }

      //users who are logged in but have not verified their emails MUST do so before doing ANYTHING else
      //TODO: address whether to re-enforce email verification as part of crit path
      //disabled because 1) firebase was sometimes failing to send out the email,
      //and 2 there were other bugs becuase the page would redirect before rsvp() flow on team invite and deal invite
      // else if (member && !verified && key != '/auth') return (
      //   <Redirect to={{pathname: '/auth'}} />
      // );

      //TODO: look again to make this safer/more logical
      //right now this enables the static frame to show even if user is anonymously logged in
      //e.g., for /contracts page
      const frameless = routeConfig.frameless || isVine;
      routeProps.frameless = frameless;

      routeProps.appWidth = this.state.appWidth;
      routeProps.appHeight = this.state.appHeight;

      return (
        <div>
          {frameless ? null : <TopBar {...appState} routeConfig={routeConfig} />}
          <ErrorView user={user}>
            <Comp {...appState} {...routeProps} {...r} />
          </ErrorView>
          {this.renderHelpBtn()}
        </div>
      );
    };

    return <Route {...routeProps} render={render} />;
  }
}

export default App;
