import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { OPERATORS } from '@core/models/Operator';
import { CONDITION_OPERATORS, DEFAULT_CONDITONAL_GROUP } from '@core/models/Section';
import { ValueType } from '@core/models/Variable';
import VariableFilter from '@core/models/VariableFilter';
import { dt } from '@core/utils/Environment';

import { Alert, Button, ButtonIcon, Checkbox, Dropdown, Icon, MenuItem, Switch } from '@components/dmp';

import VariableFilterView from '@components/VariableFilterView';
import { HELP } from '@root/CRM';
import Fire from '@root/Fire';

@autoBindMethods
export default class ConditionEditor extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };

    this.refVarFilter = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { saving } = this.state;

    //Wait for new props/changes to be recieved before unlocking conditional editing.
    //This is because when we have large complex deals there are timing issues between when
    //dealview processes the changes and passes them down to when you can preform another action.
    if (this._isMounted && saving) {
      this.setState({ saving: false });
    }
  }

  saving() {
    this.setState({ saving: true });
    //If the save takes longer than 2 seconds unlock everything
    setTimeout(() => {
      if (this._isMounted) this.setState({ saving: false });
    }, 2000);
  }

  async groupLegacyCondtionals() {
    const { section } = this.props;
    if (section.conditionGroups.length === 0 && section.conditions.length > 0) {
      //populate legacy conditionals.
      _.forEach(section.conditions, (condition, index) => {
        section.conditionGroups.push(JSON.parse(JSON.stringify(DEFAULT_CONDITONAL_GROUP)));
        section.conditionGroups[index].conditions.push(condition.variable);
      });

      await Fire.saveSection(section, {
        conditionGroups: section.conditionGroups,
        complexConditions: !section.complexConditions,
      });
    }
  }

  async enableComplexConditions() {
    const { section, onUpdate } = this.props;

    this.saving();

    if (!section.complexConditions) {
      await this.groupLegacyCondtionals();
    } else {
      await Fire.saveSection(section, { conditionGroups: [], complexConditions: !section.complexConditions });
    }

    onUpdate();
  }

  async editOperator(operator, index, operatorType) {
    const { section, onUpdate } = this.props;

    this.saving();

    section.conditionGroups[index][operatorType] = operator;
    await Fire.saveSection(section, { conditionGroups: section.conditionGroups });

    onUpdate();
  }

  async addCondition(varName, index) {
    const { section, onUpdate } = this.props;

    this.saving();

    const valueType = section.deal.variables[varName].valueType;

    section.conditions.push(new VariableFilter(varName, { valueType, operator: OPERATORS.KNOWN.key }));

    if (section.complexConditions) {
      if (!section.conditionGroups[index])
        section.conditionGroups[index] = JSON.parse(JSON.stringify(DEFAULT_CONDITONAL_GROUP));
      section.conditionGroups[index].conditions.push(varName);
      await Fire.saveSection(section, { conditionGroups: section.conditionGroups, conditions: section.conditionsJSON });
    } else {
      await Fire.saveSection(section, { conditions: section.conditionsJSON });
    }

    onUpdate();
  }

  async removeCondition(varName) {
    const { section, onUpdate } = this.props;
    let updates = {};

    this.saving();

    const idx = _.findIndex(section.conditions, { variable: varName });

    const groupIndex = _.findIndex(section?.conditionGroups, (group) => {
      const found = _.find(group.conditions, (condition) => {
        return condition === varName;
      });
      if (found) {
        return group;
      }
    });

    if (groupIndex !== -1) {
      _.remove(section.conditionGroups[groupIndex].conditions, (condition) => {
        return condition === varName;
      });

      if (section.conditionGroups[groupIndex].conditions.length === 0) {
        section.conditionGroups.splice(groupIndex, 1);
      }

      updates.conditionGroups = section.conditionGroups;
    }

    if (idx > -1) {
      section.conditions.splice(idx, 1);
      updates.conditions = section.conditionsJSON;
    }

    if (_.size(updates) > 0) {
      await Fire.saveSection(section, updates);
    }

    onUpdate();
  }

  async toggleOption(variable, option) {
    const { section, onUpdate } = this.props;

    this.saving();

    const condition = _.find(section.conditions, { variable: variable.name });
    const values = condition.values;

    //add or remove the passed-in option to the condition's current set of allowable values, which is in state
    const idx = values.indexOf(option);
    if (idx > -1) values.splice(idx, 1);
    else values.push(option);

    //now ensure that all values in state are actual options of the variable
    //this is necessary to cleanup condition values if the variable options change after conditions are set
    _.remove(values, (item) => _.findIndex(variable.options, { key: item }) == -1);

    await Fire.saveSection(section, { conditions: section.conditionsJSON });

    onUpdate();
  }

  variableFilterSave = _.debounce(async (variableFilter) => {
    const { section, onUpdate } = this.props;

    this.saving();
    let conditionIndex = _.findIndex(section.conditions, { variable: variableFilter.variable });

    section.conditions[conditionIndex] = variableFilter;

    await Fire.saveSection(section, { conditions: section.conditionsJSON });
    onUpdate();
  }, 300);

  get instructions() {
    const { section } = this.props;
    if (!section.deal.conditionals.length) {
      return (
        <span>
          This {section.deal.isTemplate ? 'template' : dt} does not have any list-type variables to be used as
          conditions. You can learn more about conditional sections{' '}
          <a className="strong" rel="noreferrer" target="_blank" href={HELP.CONDITIONALS}>
            here
          </a>
          .
        </span>
      );
    } else if (section.conditions.length) {
      return `This section will only be included in the ${dt} if all conditions below are met.`;
    } else {
      return section.isColumn
        ? 'Conditionals may only be added to single-column layouts unless placed inside captions.'
        : `There are no conditions on this section, so it will always be included in the ${dt}.`;
    }
  }

  renderCondition(value, index, groupIndex) {
    const { saving } = this.state;
    const { section } = this.props;
    const varName = value.variable;
    const variable = section.deal.variables[varName];
    const condition = section.conditions.find((condition) => condition.variable === varName);

    const conditionGroup = section.complexConditions
      ? _.find(section.conditionGroups, (group) => {
          return _.find(group.conditions, (condition) => {
            return condition === varName;
          });
        })
      : null;

    if (!variable || !condition) return null;

    const redactionError =
      variable.isRedacted && ![OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(condition.operator.key);

    return (
      <div key={index}>
        <div
          className={cx(
            'condition',
            { hasError: condition.errorMsg || redactionError },
            { complex: section.complexConditions }
          )}
          key={varName}
          data-cy="render-condition"
        >
          <Icon name="conditional" className="condition-icon" />
          <div className="var-conditions">
            <div className={cx('item-label', { error: condition.errorMsg })}>{varName}</div>

            {redactionError && (
              <Alert dmpStyle="danger" size="small" className="condition-redcted-alert">
                Redacted variables can only be used with <b>Known</b> and <b>Unknown</b> conditions.
              </Alert>
            )}

            <div className="variable-options" data-cy="variable-options">
              <VariableFilterView
                ref={this.refVarFilter}
                configOnly
                variable={variable}
                onChange={this.variableFilterSave}
                filter={_.find(this.props.section.conditions, { variable: variable.name })}
                disabled={saving}
              />
            </div>
          </div>

          <ButtonIcon
            className="remove"
            icon="trash"
            size="default"
            onClick={() => this.removeCondition(varName)}
            data-cy="btn-remove-condition"
            disabled={saving}
          />
        </div>
        {section.complexConditions &&
          conditionGroup.conditions.length > 1 &&
          index !== conditionGroup.conditions.length - 1 &&
          this.renderConditionalOperator(index !== 0, conditionGroup, groupIndex, 'individualOperator')}
      </div>
    );
  }

  renderInlineAddCondition(index) {
    const { saving } = this.state;
    const { section } = this.props;
    const availableConditions = _.filter(
      section.deal.conditionals,
      (variable) => !_.find(section.conditions, { variable: variable.name })
    ).sort((a, b) => ((a.displayName || a.name).toLowerCase() > (b.displayName || b.name).toLowerCase() ? 1 : -1));

    return (
      <div className={cx('new-condition', { complex: section.complexConditions })} data-cy="new-condition">
        <div className="display-label">
          <Icon name="plus2" />
          <div className="item-label">Add condition</div>
        </div>
        <Dropdown
          className="new-condition-dd"
          size="small"
          id="dd-add-condition"
          onSelect={(variable) => this.addCondition(variable, index)}
          title="Select Variable"
          data-cy="dd-add-condition"
          disabled={saving || section.isColumn}
        >
          {availableConditions.map((variable) => (
            <MenuItem key={variable.name} eventKey={variable.name}>
              {variable.displayName || variable.name}
              {variable.valueType === ValueType.CONTACT && ' - Full Name'}
            </MenuItem>
          ))}
        </Dropdown>
      </div>
    );
  }

  renderConditionGroups(conditions) {
    const { section } = this.props;
    const length = [...section.conditionGroups].length;

    return _.map([...section.conditionGroups, DEFAULT_CONDITONAL_GROUP], (value, index) => {
      //group by the variable name lookup
      const group = _.filter(conditions, (condition) => {
        const found = _.find(value.conditions, (varName) => {
          return condition.variable === varName;
        });
        if (found) return condition;
      });

      const showOperator = index !== 0;
      const disableOperator = index === length;

      return (
        <div className="conditional-group" key={index}>
          {showOperator && this.renderConditionalOperator(disableOperator, value, index, 'groupOperator')}
          {_.map(group, (condition, i) => this.renderCondition(condition, i, index))}
          {this.renderInlineAddCondition(index)}
        </div>
      );
    });
  }

  renderConditionalOperator(disableOperator, value, index, operatorType) {
    const { saving } = this.state;
    return (
      <div
        className={cx('condition-operator-selector', { inline: operatorType === 'individualOperator' })}
        data-cy="condition-operator-selector"
      >
        <span className="dotted-line" />
        <div className="button-group btn-group">
          <Button
            className={cx('condition-operator-and', { active: value[operatorType] === CONDITION_OPERATORS.AND })}
            size="small"
            onClick={() => this.editOperator(CONDITION_OPERATORS.AND, index, operatorType)}
            disabled={disableOperator || saving}
            data-cy="btn-condition-operator-and"
          >
            AND
          </Button>
          <Button
            className={cx('condition-operator-or', { active: value[operatorType] === CONDITION_OPERATORS.OR })}
            size="small"
            onClick={() => this.editOperator(CONDITION_OPERATORS.OR, index, operatorType)}
            disabled={disableOperator || saving}
            data-cy="btn-condition-operator-or"
          >
            OR
          </Button>
        </div>
        <span className="dotted-line" />
      </div>
    );
  }

  render() {
    const { saving } = this.state;
    const { section } = this.props;
    if (!section) return null;

    return (
      <div className="condition-editor">
        <Alert dmpStyle="info" size="small" className="condition-alert">
          {this.instructions}
        </Alert>
        {section.conditions && section.complexConditions && (
          <div className="existing">{this.renderConditionGroups(section.conditions)}</div>
        )}
        {section.conditions && !section.complexConditions && (
          <div>
            <div className="existing">{_.map(section.conditions, this.renderCondition)}</div>
            {this.renderInlineAddCondition()}
          </div>
        )}
        <Switch
          id="complex-switch"
          className="complex-switch"
          checked={section.complexConditions}
          onChange={this.enableComplexConditions}
          size="small"
          disabled={section.conditions.length === 0 || saving}
        >
          Complex conditions
        </Switch>
      </div>
    );
  }

  renderOption(variable, option, index) {
    const { saving } = this.state;
    const condition = _.find(this.props.section.conditions, { variable: variable.name }) || [];
    return (
      <Checkbox
        className="condition-option"
        id={`check-opt-${variable.name}-${index}`}
        key={index}
        checked={condition.values.indexOf(option.key) > -1}
        onChange={() => this.toggleOption(variable, option.key)}
        data-cy="condition-option"
        disabled={saving}
      >
        {option.title}
      </Checkbox>
    );
  }
}
