import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import AdminSearchParams from '@core/models/AdminSearchParams';
import Team from '@core/models/Team';
import TeamRecord from '@core/models/TeamRecord';
import User from '@core/models/User';

import { ButtonIcon, DataTable } from '@components/dmp';

import { TEAM_MANAGEMENT_COLUMNS } from '@components/deal/Columns';
import MemberInfo from '@components/teams/MemberInfo';
import API from '@root/ApiClient';
import TeamEditor from '@routes/admin/TeamEditor';

@autoBindMethods
export default class TeamsTab extends Component {
  static propTypes = {
    og: PropTypes.func.isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
    user: PropTypes.instanceOf(User),
    adminSearchParams: PropTypes.instanceOf(AdminSearchParams),
    getTeams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPage: 0,
      totalPages: 0,
      selectedTeam: null,
      isAddingTeamMember: false,
      isEditingTeam: false,
    };

    this.onSearchTeam = _.debounce((value) => {
      this.props.adminSearchParams.query = value;
    }, 150);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.og({ title: 'Outlaw - Admin - Teams' });
    this.getTeams();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const adminSearchParams = _.get(this.props, 'adminSearchParams');
    const prevParams = _.get(prevProps, 'adminSearchParams');
    // Anytime SearchParams changes, run a new search
    if (!_.isEqual(adminSearchParams, prevParams)) {
      this.getTeams();
    }
  }

  async getTeams() {
    const { adminSearchParams } = this.props;
    this.setState({ loading: true });

    API.call('getTeams', adminSearchParams.apiArgs, (results) => {
      if (!this._isMounted) return;

      const data = _.map(results.hits, (record) => new TeamRecord(record));

      this.setState({
        data,
        totalHits: results.nbHits,
        currentPage: results.page,
        totalPages: results.nbPages,
        loading: false,
      });
    });
  }

  async onAddTeamMember({ uid }) {
    const { user, getTeams } = this.props;
    // If we are adding ourselves to a Team, we must reload them in the global state
    if (uid === user.id) {
      await getTeams(user);
    }

    this.setState({ isAddingTeamMember: false, selectedTeam: null });
    this.getTeams();
  }

  onSortChange(newSorted) {
    const { adminSearchParams } = this.props;
    // Since we're not doing multi-column sorting, let's simply grab the first item in the array
    const sortObject = _.get(newSorted, '[0]');
    const { desc, id } = sortObject;
    adminSearchParams.sort = `${id}.${desc ? 'desc' : 'asc'}`;
  }

  get sorted() {
    const { adminSearchParams } = this.props;
    const sort = _.get(adminSearchParams, 'sort');

    let sortBy, sortDir;
    [sortBy, sortDir] = sort.split('.');
    return [{ id: sortBy, desc: !!(sortDir === 'desc') }];
  }

  get actionsColumn() {
    return {
      key: 'actions',
      id: 'actions',
      Cell: ({ original }) => (
        <div className="d-flex justify-content-between">
          <ButtonIcon
            icon="plus2"
            size="default"
            data-cy="add-team-member"
            onClick={() => this.setState({ selectedTeam: original, isAddingTeamMember: true })}
          />
          <ButtonIcon
            icon="deal"
            size="default"
            data-cy="edit-team"
            onClick={() => this.setState({ selectedTeam: original, isEditingTeam: true })}
          />
        </div>
      ),
      minWidth: 60,
      maxWidth: 60,
      className: 'col-actions',
      fixed: 'right',
      sortable: false,
      clickable: false,
      resizable: false,
    };
  }

  get columns() {
    return [...TEAM_MANAGEMENT_COLUMNS, this.actionsColumn];
  }

  getTdProps(state, rowInfo, column) {
    const { getTeams, teams, user } = this.props;
    const { original } = rowInfo;
    const team = _.find(teams, (team) => team.teamID === original.teamID);

    let onClick = _.noop;
    if (team) onClick = () => getTeams(user, team.teamID);

    const tdProps = {
      onClick,
      isLocked: team ? false : true,
      to: null,
    };

    if (column.clickable === true && team) {
      tdProps.to = '/dashboard/team';
    }

    return tdProps;
  }

  render() {
    const { currentPage, data, totalPages, loading, selectedTeam, isEditingTeam, isAddingTeamMember } = this.state;
    const { adminSearchParams, user } = this.props;

    return (
      <div className="admin-tab-header">
        <div className="d-flex justify-content-between align-items-center flex-wrap " data-cy="admin-tab-header">
          <h4>Teams</h4>
          <div className="d-flex">
            <div className="admin-search-teams" data-cy="admin-search-teams">
              <FormControl
                type="text"
                onChange={(e) => this.onSearchTeam(e.target.value)}
                placeholder="Search team by name"
                data-cy="admin-search-teams-input"
              />
            </div>
          </div>
        </div>
        <div className="teams-list">
          <DataTable
            getTdProps={this.getTdProps}
            columns={this.columns}
            dropshadow
            hasFixedColumns
            loading={loading}
            loadingText="Fetching..."
            manual
            data={data}
            onPageChange={(pageIndex) => adminSearchParams.setPage(pageIndex + 1)}
            onPageSizeChange={(pageSize) => (adminSearchParams.hitsPerPage = pageSize)}
            page={currentPage}
            pages={totalPages}
            pageSize={adminSearchParams.hitsPerPage}
            showPaginationTop
            onSortedChange={this.onSortChange}
            sorted={this.sorted}
            sortable
          />
        </div>
        {/* We are passing a TeamRecord vs a Team but that will do for now until we refactor it */}
        {/* We must pass in an empty member so that the Modal shows and start populating a new one */}
        {isAddingTeamMember && (
          <MemberInfo
            member={{}}
            multiOwners={true}
            checkSubscription={_.noop}
            user={user}
            team={selectedTeam}
            showTeamID={true}
            onSave={this.onAddTeamMember}
            onHide={() => this.setState({ isAddingTeamMember: false, selectedTeam: null })}
          />
        )}
        {isEditingTeam && (
          <TeamEditor
            team={selectedTeam}
            onHide={() => this.setState({ isEditingTeam: false, selectedTeam: null })}
            onSave={() => {
              this.setState({ isEditingTeam: false, selectedTeam: null });
              this.getTeams();
            }}
          />
        )}
      </div>
    );
  }
}
