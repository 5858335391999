import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import csv from 'csvtojson';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormGroup, Modal, ProgressBar } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import DealAction from '@core/enums/DealAction';
import Attachment, { ACCEPTED_TYPES, ATTACHMENT_TYPE } from '@core/models/Attachment';
import DealFactory from '@core/models/DealFactory';
import DealMetaCSV, { getBoilerplate } from '@core/models/DealMetaCSV';
import DealVersion from '@core/models/DealVersion';
import { TEAM_ROLES } from '@core/models/Team';
import Teammate from '@core/models/Teammate';
import { FEATURES } from '@core/models/User';
import { BATCH_ID_VAR, FV_PROJECT_ID_VAR, VariableType } from '@core/models/Variable';
import { downloadLink } from '@core/utils/CSV';
import { DateFormatter } from '@core/utils/DateTime';
import { Dt, dt } from '@core/utils/Environment';
import { getFileExtension } from '@core/utils/File';
import { arrayToBase64, getBaseUrl, getDealUrl, getUniqueKey, getUrlFromInvite } from '@core/utils/Generators';
import Stopwatch from '@core/utils/Stopwatch';

import {
  Alert,
  Button,
  Card,
  Checkbox,
  DataTable,
  Dropdown,
  Icon,
  Loader,
  MenuItem,
  ModalConfirm,
  Switch,
} from '@components/dmp';

import TeamSelector from '@components/teams/TeamSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import { ACTIONS, PROCESS_STATUS, getColumns, trProps } from '@components/vault/BatchColumns';
import API from '@root/ApiClient';
import CONFIG from '@root/Config';
import Fire from '@root/Fire';

// Determine how many uploads we want to enable at once
// Use 1 until Watcher is more solid (https://trello.com/c/OP5ElwXt/605-build-watcher-v2-the-john-snow-not-the-samwell-tarly)
const MAX_CONCURRENT = 4;

// Limit the batch size for regular customers
const MAX_BATCH_SIZE = 250;

// Limit the batch size for admin customers
// TODO: do we want separate size limitations for Tasks?
const MAX_ADMIN_BATCH_SIZE = 10000;
// Limit the PDF size for admin customers
const MAX_ADMIN_PDF_SIZE = 10000;

const FILE_TYPES = {
  PDF: 'application/pdf',
};

const SHARING_MODES = [
  { key: 'link', title: 'Generate invite link' },
  { key: 'email', title: 'Send invite email' },
];

const PUSH_TO_FILEVINE_FORMATS = [
  { key: 'docx', title: '.DOCX' },
  { key: 'pdf', title: '.PDF' },
];

const DATE_MODES = [
  { key: 'today', title: `Use today's date as ${dt} creation date` },
  { key: 'file', title: 'Use last updated date of uploaded file' },
];

const BatchSetting = ({ title, children, className }) => {
  return (
    <div className={cx('batch-setting', className)}>
      <div className="setting-title">{title}</div>
      <div className="setting-content">{children}</div>
    </div>
  );
};

const { USE_LEGACY_BATCH_OPS } = CONFIG;

@autoBindMethods
export default class TotalBatch extends Component {
  sw = null;

  static propTypes = {
    subscription: PropTypes.object,
    team: PropTypes.object,
    teams: PropTypes.array,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      simulate: false,
      csvFile: null,
      csvError: null,
      invalidParties: [],
      invalidVariables: [],
      confirming: false,
      confirmed: false,
      metas: [],
      parties: [],
      sharingMode: 'link',
      dateMode: 'today',
      pdfs: {},
      batchTeam: null,
      processing: 0,
      batchID: getUniqueKey(),
      done: false,
      template: null,
      branding: null,
      action: ACTIONS.UPLOAD.key,
      // Default values for all imported deals
      teammates: [new Teammate(props.user)],
      isOverLimit: false,
      bypassValidation: false,
      batchTaskConfirm: false,
      useTasks: true,
      creatingTask: false,
      taskProgress: null,
      canPushToFilevine: false,
      pushToFilevine: false,
      pushToFilevineFormats: [],
    };

    this.download = createRef();
  }

  get action() {
    return _.find(ACTIONS, { key: this.state.action });
  }

  get isReady() {
    const { user } = this.props;
    const { metas, processing, action, invalidParties, invalidVariables } = this.state;

    if (!metas.length || processing || invalidParties.length || invalidVariables.length) return false;

    switch (action) {
      case ACTIONS.UPLOAD.key:
        let isReady = true;
        if (this.missingStatuses) {
          isReady = false;
        } else if (!user.isAdmin && this.missingPDFs) {
          isReady = false;
        }

        return isReady;
      case ACTIONS.CREATE.key:
        return true;
      case ACTIONS.UPDATE.key:
      case ACTIONS.DELETE.key:
        return _.filter(metas, 'dealID').length === metas.length;
      default:
        return false;
    }
  }

  get ctaDrop() {
    const { template } = this.state;
    return template ? 'Drop fully populated metadata CSV here' : 'Select a template above';
  }

  get completedItems() {
    return _.filter(this.state.metas, { processStatus: PROCESS_STATUS.DONE.key });
  }

  get downloadLink() {
    const { metas } = this.state;
    if (!metas.length) return '';
    return downloadLink(_.map(metas, 'raw'));
  }

  get downloadFilename() {
    const { csvFile } = this.state;
    const now = DateFormatter.iso(new Date());
    const filename = _.get(csvFile, 'name', 'Batch').split('.')[0];
    return `${now}-${filename}-${this.action.verb.past.toUpperCase()}.csv`;
  }

  get boilerplateLink() {
    const { template, action } = this.state;
    return downloadLink(getBoilerplate(template, action));
  }

  get boilerplateFilename() {
    return `Outlaw - ${this.action.verb.present.toUpperCase()} - ${this.state.template.template.title}.csv`;
  }

  get showCSV() {
    return !!this.state.template;
  }

  get operationStatus() {
    const { processing, done, metas } = this.state;

    if ((processing || done) && this.completedItems.length > 0) {
      const completedDeals = this.completedItems.length > 1 ? `${dt}s` : dt;
      return `(Successfully ${this.action.verb.past} ${this.completedItems.length} ${completedDeals})`;
    }

    if (this.isReady) {
      return `(Ready to ${this.action.verb.present} ${metas.length} ${dt}s.)`;
    }

    if (this.missingPDFs > 0) {
      return `(${this.missingPDFs} of ${metas.length} missing)`;
    }

    return '';
  }

  get missingPDFs() {
    const { metas, pdfs, action } = this.state;
    if (action !== ACTIONS.UPLOAD.key) return 0;
    return metas.length - _.keys(pdfs).length;
  }

  get missingStatuses() {
    const { metas, action } = this.state;
    if (action !== ACTIONS.UPLOAD.key) return 0;
    return _.filter(metas, (meta) => !meta.status).length;
  }

  get metaErrors() {
    const { invalidParties, invalidVariables } = this.state;
    const errors = [];
    if (invalidParties.length > 0) {
      errors.push(`Parties not found in the selected template: [${invalidParties.join(', ')}]`);
    }

    if (invalidVariables.length > 0) {
      errors.push(`Variables not found in the selected template: [${invalidVariables.join(', ')}]`);
    }

    const missingStatuses = this.missingStatuses;
    if (missingStatuses > 0) {
      errors.push(`${missingStatuses} rows do not have a ${dt} status specified`);
    }

    return errors;
  }

  async loadTemplate(template) {
    if (!template) return this.setState({ template: null });
    const dealTemplate = await Fire.getDeal(template.dealID);
    const branding = await Fire.getBranding(null, template);
    this.setState({ template: dealTemplate, branding, parties: [] });
  }

  clearTemplate() {
    this.setState({ template: null, branding: null, parties: [] });
  }

  toggleParty(partyID) {
    const { parties } = this.state;
    const idx = parties.indexOf(partyID);
    if (idx > -1) parties.splice(idx, 1);
    else parties.push(partyID);
    this.setState({ parties });
  }

  /*
    Since Windows is handling CSV files badly, we will accept any file type,
    lightly validate with the file extension in the file name then try parsing it.
    https://www.christianwood.net/csv-file-upload-validation/#tldr
  */
  onCSVDrop(files) {
    const { user } = this.props;
    const { action, batchID, isOverLimit } = this.state;
    const file = files[0];
    let csvError = null;

    if (file && getFileExtension(file.name) !== 'csv') {
      csvError = `"${file.name}" is not a valid file. (${file.name}:${file.type})`;
    }

    this.setState({ csvError });

    // Return if we didn't get a potential csv file
    if (csvError) {
      // TODO: fix this, reset hides the error
      this.reset();
      return;
    }

    const reader = new FileReader();

    reader.onload = async () => {
      try {
        const rows = await csv().fromString(reader.result);

        // Make sure that we do not hit limits
        let csvError = null;
        if (!isOverLimit && rows.length > MAX_BATCH_SIZE) {
          csvError = `You cannot generate more than ${MAX_BATCH_SIZE} ${Dt}s.`;
        }

        if (isOverLimit && action === ACTIONS.CREATE.key && rows.length > MAX_ADMIN_BATCH_SIZE) {
          csvError = `You cannot generate more than ${MAX_ADMIN_BATCH_SIZE} ${Dt}s.`;
        }

        if (isOverLimit && action === ACTIONS.UPLOAD.key && rows.length > MAX_ADMIN_PDF_SIZE) {
          csvError = `You cannot generate more than ${MAX_ADMIN_PDF_SIZE} ${Dt}s.`;
        }

        if (csvError) {
          this.setState({ csvError });
          return;
        }

        let canPushToFilevine = false;
        // We need to always ensure that Batch-ID is present, so that these contracts can be easily targeted
        // fill one in here if not specified in source CSV data
        const metas = _.map(rows, (json) => {
          if (!_.get(json, `#${BATCH_ID_VAR.name}`)) {
            json[`#${BATCH_ID_VAR.name}`] = batchID;
          }
          if (action === ACTIONS.CREATE.key && _.get(json, `+${FV_PROJECT_ID_VAR.name}`)) {
            canPushToFilevine = true;
          }
          return new DealMetaCSV(json, action);
        });

        await this.setState({ metas, csvFile: files[0], canPushToFilevine });
        this.validate();
      } catch (readError) {
        this.reset();
        this.setState({ csvError: 'CSV import error, contact Outlaw customer support.' });
        throw readError;
      }
    };

    reader.readAsText(files[0]);
  }

  selectTeam = (teamID) => {
    this.setState({ batchTeam: _.find(this.props.teams, { teamID }) || null, template: null });
  };

  clearTeam() {
    this.setState({ batchTeam: null, template: null });
  }

  reset() {
    this.setState({
      batchID: getUniqueKey(),
      metas: [],
      template: null,
      branding: null,
      parties: [],
      csvFile: null,
      csvError: null,
      confirming: false,
      confirmed: false,
      batchTaskConfirm: false,
      invalidParties: [],
      invalidVariables: [],
      pdfs: {},
      processing: 0,
      done: false,
      taskProgress: null,
      creatingTask: false,
      canPushToFilevine: false,
      pushToFilevine: false,
      pushToFilevineFormats: [],
    });
  }

  validate() {
    let { pdfs, metas, action, template, invalidParties, invalidVariables } = this.state;

    _.forEach(metas, (meta) => {
      let metaBadParties = [],
        metaBadVars = [];

      if (!this.state.bypassValidation) {
        metaBadParties = meta.validateParties(template);
        metaBadVars = meta.validateVariables(template);
        invalidParties = _.chain(invalidParties).concat(metaBadParties).uniq().value();
        invalidVariables = _.chain(invalidVariables).concat(metaBadVars).uniq().value();
      }

      // Both upload and generation can have variable/party errors
      if (metaBadVars.length > 0 || metaBadParties.length > 0) {
        meta.processStatus = PROCESS_STATUS.DATA_ERROR.key;
      } else {
        // uploads also need status defined and valid file
        if (action === ACTIONS.UPLOAD.key) {
          if (!meta.status) {
            meta.processStatus = PROCESS_STATUS.STATUS_ERROR.key;
          } else if (!meta.filename || !pdfs[meta.filename]) {
            meta.processStatus = PROCESS_STATUS.MISSING.key;
          } else {
            meta.processStatus = PROCESS_STATUS.READY.key;
          }
        } else {
          meta.processStatus = PROCESS_STATUS.READY.key;
        }
      }
    });
    this.setState({ metas, invalidParties, invalidVariables });
  }

  async onPDFAccepted(files) {
    const { metas, pdfs } = this.state;
    let changes = false;
    files.map((file) => {
      // For each dropped file, look it up in meta list
      const meta = _.find(metas, { filename: file.name });
      if (meta) {
        pdfs[file.name] = file;
        changes = true;
      }
    });

    if (changes) {
      await this.setState({ pdfs, metas });
      this.validate();
    }
  }

  async processItem(meta) {
    const { user, team } = this.props;
    const { dateMode, action, template, branding, parties, metas, processing, pdfs, simulate } = this.state;
    const itemIndex = metas.length - _.filter(metas, { processStatus: PROCESS_STATUS.READY.key }).length;

    this.sw.step(`Process Item [${itemIndex}/${metas.length}] Start`);

    const file = meta.filename ? pdfs[meta.filename] : null;

    const forceExternal = action === ACTIONS.UPLOAD.key && user.isAdmin ? true : false;

    let dealParams;
    if (action !== ACTIONS.DELETE.key) {
      dealParams = _.merge(meta.buildDealCreationParams(user, template, forceExternal), {
        lastModified: dateMode === 'file' && !!file ? file.lastModified.toString() : new Date().getTime().toString(),
        createdFromBatch: true,
      });
    }

    let newDealID, newDeal, attachment;

    meta.processStatus = PROCESS_STATUS.UPLOADING.key;
    await this.setState({ metas, processing: processing + 1 });

    if (simulate) {
      await this.simulateImport(meta, dealParams);
      return;
    }

    switch (action) {
      case ACTIONS.UPLOAD.key:
        // First create the wrapper Deal object so that we get all the goodies (users, status etc)
        newDealID = await Fire.createDeal(dealParams);
        newDeal = await Fire.getDeal(newDealID);
        await Fire.addActivity(newDeal, user, DealAction.CREATE);

        // Now created the associated Attachment and file upload, and attach the DealVersion
        if (file) {
          attachment = new Attachment(
            {
              extension: ACCEPTED_TYPES.PDF.extension,
              title: dealParams.title,
              attachmentType: ATTACHMENT_TYPE.VERSION,
            },
            newDeal
          );
          await Fire.saveAttachment(attachment, file);
        }

        await Fire.saveDealVersion(
          new DealVersion(
            {
              owner: user.id,
              pdfKey: attachment ? attachment.key : null,
              dateCreated: new Date().getTime().toString(),
            },
            newDeal
          )
        );

        // ...and we're done!
        this.sw.step(`Process Item [${itemIndex}/${metas.length}] Done`);
        await this.onContractImportComplete(meta, newDealID);
        break;

      case ACTIONS.CREATE.key:
        dealParams.branding = branding;
        let dealTemplate = await Fire.getDeal(template.dealID);
        //if the dealTemplate is a bundle generate the bundled contracts
        if (dealTemplate.isBundle) {
          const { teamID, templateKey } = dealParams;
          const bundle = await API.call('createBundle', {
            teamID: teamID,
            templateKey: templateKey,
            userOrigin: user.userOrigin,
            name: dealParams.title,
            customDealParams: dealParams,
          });
          newDealID = bundle.parent;
        }
        //otherwise its a normal deal.
        else {
          newDeal = await Fire.createDealFromTemplate({
            user,
            dealTemplate,
            branding,
            connections: dealParams.connections,
            name: dealParams.title,
            customDealParams: dealParams,
            createdFromBatch: true,
          });
          // Fire.addActivity requires a proper Deal instance to work correctly
          newDeal = DealFactory.create(newDeal);
          newDealID = newDeal.dealID;
          await Fire.addActivity(newDeal, user, DealAction.CREATE);

          if (newDeal.isConnected && Object.keys(newDeal.syncedVariables).length) {
            API.call('syncConnectVariables', { dealID: newDealID, deal: newDeal });
          }
        }
        if (parties.length > 0) {
          newDeal = await Fire.getDeal(newDealID);
          const invites = [];
          _.map(parties, (partyID) => {
            invites.push(
              new Promise(async (resolve) => {
                await this.sendContract(meta, newDeal, partyID);
                resolve();
              })
            );
          });
          await Promise.all(invites);
        }
        this.sw.step(`Process Item [${itemIndex}/${metas.length}] Done`);
        await this.onContractImportComplete(meta, newDealID);
        break;

      case ACTIONS.UPDATE.key:
        // We can update all vars in one go, but need to convert to a simple key/value object for Fire call
        const vars = _.mapValues(
          _.pickBy(dealParams.variables, { type: VariableType.SIMPLE }),
          ({ value }) => value || null
        );
        if (_.keys(vars).length > 0) {
          await Fire.saveVariables(meta.dealID, vars);
        }
        await this.onContractImportComplete(meta, meta.dealID);
        break;
      case ACTIONS.DELETE.key:
        try {
          // Hard delete deals
          await Fire.deleteDeal(meta.dealID);
        } catch (e) {
          console.log(`Permissions error deleting deal [${meta.dealID}];`);
        }
        this.sw.step(`Process Item [${itemIndex}/${metas.length}] Done`);
        await this.onContractImportComplete(meta, meta.dealID);
        break;
      default:
        break;
    }
  }

  // Useful for UI dev purposes -- simulate queued/batching without actually creating deals
  async simulateImport(meta, dealParams) {
    const { pdfs, metas, action } = this.state;
    const file = pdfs[meta.filename];

    //simulate upload that takes between 1 and 3 seconds
    const time = (Math.floor(Math.random() * Math.floor(1)) + 2) * 1000;
    const name = _.get(file, 'name', 'imported contract');
    console.log(`${time}ms - simulating [${action}] of [${name}]`, dealParams);

    meta.processStatus = PROCESS_STATUS.UPLOADING.key;
    await this.setState({ metas });

    setTimeout(async () => {
      console.log(`Simulated upload complete for [${name}]`);
      this.onContractImportComplete(meta);
    }, time);
  }

  async sendContract(meta, deal, partyID) {
    // Copied/modified from <SendDeal>
    const { user } = this.props;
    const { sharingMode } = this.state;

    let dealUsers = deal.getUsersByParty(partyID);

    // No need to invite Users who are already on the Deal
    dealUsers = dealUsers.filter((dealUser) => !dealUser.uid);

    if (dealUsers.length > 0) {
      const promises = [];
      _.forEach(dealUsers, (du) => {
        // Invites can only be emailed if an email is supplied
        // If no email is supplied, we can still generate links
        if (!du.email && sharingMode !== 'link') return;
        promises.push(
          new Promise(async (resolve) => {
            try {
              const linkOnly = sharingMode === 'link';
              const invite = await API.call('invite', {
                dealID: deal.dealID,
                inviter: user,
                email: du.email,
                du: du.json(),
                linkOnly,
              });
              console.log(`Created [${sharingMode}] invite to ${du.email} (${partyID}) on Deal [${deal.dealID}]`);

              // In either case, capture the inviteURL in the "output" which is the original meta object passed in props
              meta.raw[`@${partyID}.inviteURL`] = getUrlFromInvite(invite, getBaseUrl());
            } catch (result) {
              const code = _.get(result, 'response.status');
              if (code === 409) {
                console.log(`User [${du.email}] is already on Deal; no invite created`);
                meta.raw[`@${partyID}.inviteURL`] = getDealUrl(deal);
              }
            }

            resolve();
          })
        );
      });
      await Promise.all(promises);
    }
  }

  processQueue() {
    const { confirmed, processing, metas } = this.state;

    // Require confirmation modal before actually processing
    if (!confirmed) {
      return this.setState({ confirming: true });
    }

    // Fetch the next items in queue, up to the maximum number of concurrent items
    if (processing < MAX_CONCURRENT) {
      const queue = _.filter(metas, { processStatus: PROCESS_STATUS.READY.key }).slice(0, MAX_CONCURRENT - processing);
      _.forEach(queue, this.processItem);
    }
  }

  async onProcessStatusChange(meta, status) {
    meta.processStatus = status;
    await this.setState({ metas: this.state.metas });
  }

  async onContractImportComplete(meta, newDealID) {
    const { processing, metas } = this.state;

    if (meta) {
      meta.processStatus = PROCESS_STATUS.DONE.key;
    }
    if (meta && newDealID) {
      // console.log(`New deal created [${newDealID}]; attaching to dealID to CSV data`);
      meta.raw._dealID = newDealID;
      meta.dealID = newDealID;
    }

    await this.setState({ metas, processing: processing - 1 });

    if (this.completedItems.length === metas.length || this.completedItems.length === metas.length - this.missingPDFs) {
      this.sw.step('Process Queue Complete!');
      await this.setState({ done: true });
      // Auto-download results if we just finished!
      const link = this.download.current;
      if (link) link.click();
    } else {
      this.processQueue();
    }
  }

  cancelConfirmation() {
    this.setState({ confirming: false });
  }

  async createBatchTask() {
    const {
      batchTeam,
      dateMode,
      action,
      sharingMode,
      template,
      parties,
      pdfs,
      csvFile,
      metas,
      pushToFilevine,
      pushToFilevineFormats,
    } = this.state;

    let { batchID } = this.state;

    if (metas[0]?.variables?.[BATCH_ID_VAR.name]) {
      batchID = metas[0].variables[BATCH_ID_VAR.name].value;
    }

    this.setState({ confirming: false, confirmed: true, done: true });
    let current = 0;
    let total = 2;

    // Build task request
    let taskType, params;
    switch (action) {
      case ACTIONS.UPLOAD.key:
        total += Object.keys(pdfs).length;
        this.setState({ creatingTask: true, taskProgress: { current, total, description: 'Uploading PDFs' } });

        taskType = 'batchUploadPDF';
        params = { dateMode, action, templateKey: template.template.key };

        // Upload PDFs
        for (const [filename, pdf] of Object.entries(pdfs)) {
          try {
            // TODO: make sure this works with large files
            const pdfData = await this.readFileAsUint8Array(pdf);
            await API.call('saveBatchPDFData', {
              pdfData: arrayToBase64(pdfData),
              filename,
              batchID,
            });
            current++;
            this.setState({ taskProgress: { current, total, description: 'Uploading PDF' } });
          } catch (error) {
            console.error(`Error saving PDF ${pdf.name}:`, error);
            // You might want to handle this error more gracefully,
            // such as updating the UI or the PDF's status
          }
        }
        break;
      case ACTIONS.CREATE.key:
        this.setState({ creatingTask: true });
        taskType = 'generateContracts';
        params = { parties, sharingMode, templateID: template.dealID, parties };

        if (pushToFilevine) {
          params.filevine = { docTypes: pushToFilevineFormats };
        }
        break;
    }

    try {
      current++;
      this.setState({ taskProgress: { current, total, description: 'Uploading CSV' } });

      // Read CSV file
      const csvData = await this.readFileAsText(csvFile);

      // Upload CSV data
      const { filename } = await API.call('saveBatchCSVData', { csvData });
      params.csvBucketFile = filename;
      params.teamID = batchTeam.teamID;
      params.batchID = batchID;
      params.dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();

      const taskRequest = {
        type: taskType,
        params,
      };
      console.log('Creating task with params', taskRequest);

      current++;
      this.setState({ taskProgress: { current, total, description: 'Creating task' } });

      await API.request({ functionName: 'tasks', method: 'post', data: taskRequest });

      this.setState({ creatingTask: false, batchTaskConfirm: true });
    } catch (error) {
      console.error('Error in createBatchTask:', error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  }

  // Helper function to read file as text
  readFileAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  readFileAsUint8Array(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const uint8Array = new Uint8Array(arrayBuffer);
        resolve(uint8Array);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }

  async start() {
    const { user } = this.props;
    const { template, metas, action } = this.state;

    await this.setState({ confirming: false, confirmed: true });

    // If we are Importing PDFs and an admin wants to import without the files, set the items as ready
    if (user.isAdmin) {
      _.forEach(metas, (meta) => (meta.processStatus = PROCESS_STATUS.READY.key));
    }

    this.sw = new Stopwatch('Process Queue');
    this.processQueue();
    const batchID = metas[0]?.variables?.['Batch-ID']?.value;
    const dealIDs = metas.map((meta) => meta.dealID);
    const subType = action === 'create' ? 'generateFromExisting' : 'upload';
    const auditDetails = {
      batchID: batchID,
      dealIDs: dealIDs,
    };
    try {
      API.call('userAuditLog', {
        teamID: template.team,
        eventCategory: 'batch',
        eventType: 'create',
        eventSubtype: subType,
        eventDetails: auditDetails,
      });
    } catch (e) {
      console.log(`There was an error:`, e);
    }
  }

  renderConfirmation() {
    const { confirming, metas, parties, sharingMode, useTasks } = this.state;

    // TODO: Remove all of the frontend processing logic once we're good with tasks
    let batchAction = this.start;
    if (useTasks && !USE_LEGACY_BATCH_OPS) {
      batchAction = this.createBatchTask;
    }

    return (
      <Modal show={confirming} onHide={this.cancelConfirmation}>
        <Modal.Header closeButton>
          <span className="headline">Confirm Batch Operation</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <p>
              You are about to {this.action.verb.present} {metas.length} {dt}s.
            </p>
            {parties.length > 0 && sharingMode === 'email' && (
              <p>
                These {dt}s will automatically be shared via email with the respective parties ({parties.join(', ')}) as
                specified in the CSV data.
              </p>
            )}
            <p>
              Once started, this process cannot be cancelled, so please ensure that all CSV data is 100% accurate before
              proceeding.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button dmpStyle="link" onClick={this.cancelConfirmation} data-cy="cancel">
            Cancel
          </Button>
          <Button dmpStyle="danger" onClick={batchAction} data-cy="start">
            Start
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Show CTA to existing customers to talk to support about migration
  renderCustomer() {
    return (
      <div className="total-batch customer">
        <div className="cta">
          <h4>Let us help you with that</h4>
          <span className="instructions">
            To ensure a seamless batch upload to Outlaw’s cloud-based repository, your Outlaw Customer Success team will
            help assist you. We’re ready to help!
          </span>
        </div>
      </div>
    );
  }

  renderTaskProgress() {
    const { creatingTask } = this.state;
    const progress = _.get(this.state, 'taskProgress', { current: 0, total: 1, description: '' });
    const percent = (progress.current / progress.total) * 100;

    return (
      <Modal className="total-batch" show={creatingTask}>
        <Modal.Header>
          <span className="headline">Task Creation Progress</span>
        </Modal.Header>
        <Modal.Body>
          <div className="saving">
            <ProgressBar bsStyle="info" now={percent} />
            <div className="details">
              <div className="step">
                {progress.current} of {progress.total}
              </div>
              <div className="description">{progress.description}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  // For now, only we (Outlaw team) can actually run the batch upload
  renderAdmin() {
    const {
      metas,
      csvFile,
      csvError,
      template,
      action,
      sharingMode,
      dateMode,
      parties,
      isOverLimit,
      bypassValidation,
      batchTeam,
      batchTaskConfirm,
      useTasks,
      creatingTask,
      canPushToFilevine,
      pushToFilevine,
      pushToFilevineFormats,
    } = this.state;
    const { teams, user } = this.props;

    const actions = _.filter(ACTIONS, 'enabled');

    const currentAction = action;

    return (
      <div className="wrapper total-batch admin" data-cy="total-batch-admin">
        <div className="title-bar">
          <h1>Batch Operations</h1>
        </div>
        <h4>Configuration</h4>
        <div className="batch-config" data-cy="batch-config">
          <Card noPadding className="op-selection" data-cy="op-selection">
            <div className="setting-title" data-cy="selection-setting-title">
              Select batch operation
            </div>

            <FormGroup>
              <Dropdown
                //size="small"
                id="dd-batch-action"
                title={this.action.title}
                onSelect={(action) =>
                  this.setState({ action: action.key, batchTeam: null, template: null, csvFile: null, metas: [] })
                }
                block
                dataCyToggle="dd-batch-action"
              >
                {_.map(actions, (action) => (
                  <MenuItem key={action.key} eventKey={action} active={action.key === currentAction}>
                    {action.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>

            <Alert dmpStyle="info" className="op-alert">
              <div className="op-steps" data-cy="op-steps">
                <ul>
                  {_.map(this.action.steps, (step, idx) => (
                    <li key={idx}>{step}</li>
                  ))}
                </ul>
              </div>
            </Alert>
          </Card>

          <Card noPadding>
            <BatchSetting title="Team">
              <TeamSelector
                user={user}
                teamID={batchTeam?.teamID}
                teams={teams}
                onSelect={this.selectTeam}
                disableNew
                size="medium"
                className="filter-team"
                onClear={this.clearTeam}
              />
            </BatchSetting>
            <BatchSetting title="Template">
              <TemplateSelector
                disabled={!batchTeam || metas.length > 0}
                team={batchTeam}
                onSelect={this.loadTemplate}
                activeOnly={true}
                selectedTemplateKey={template?.template?.key || null}
                filter={(templates, team) =>
                  _.filter(templates, (template) => {
                    return template.batch && team && team.users[user.id] !== TEAM_ROLES.VIEWER.value;
                  })
                }
                type="dropdown"
                size="medium"
                onClear={this.clearTemplate}
              />

              {this.showCSV && (
                <div className="template-download" data-cy="template-download">
                  CSV metadata for batch operations is required to be formatted according to the specific action and
                  template. Please download the boilerplate CSV file below to ensure correct formatting as you populate
                  your metadata.
                  <a data-cy="download-csv" download={this.boilerplateFilename} href={this.boilerplateLink}>
                    Download boilerplate CSV - {template.template.title}
                  </a>
                </div>
              )}
            </BatchSetting>

            {this.showCSV && (
              <BatchSetting title="Metadata">
                <Dropzone
                  disabled={!template}
                  activeClassName="dropping"
                  className={cx('csv-up', { populated: !!csvFile })}
                  disableClick={!!csvFile}
                  multiple={false}
                  onDrop={this.onCSVDrop}
                  data-cy="csv-up"
                >
                  {csvFile && (
                    <div className="hit-area">
                      <div className="instructions">
                        <div className="csv-file">{csvFile.name}</div>
                        <small>
                          {metas.length} {dt} metadata row{metas.length > 1 ? 's' : ''} found.
                        </small>
                      </div>
                      <Button className="btn-clear" size="small" onClick={this.reset} data-cy="btn-clear-csv">
                        Clear
                      </Button>
                    </div>
                  )}

                  {!csvFile && (
                    <div className="hit-area" data-cy="drop-csv-hit-area">
                      <div className="instructions">
                        <div className="drop-csv">{this.ctaDrop}</div>
                        {csvError && <small className="error">{csvError}</small>}
                      </div>
                    </div>
                  )}
                </Dropzone>

                {canPushToFilevine && (
                  <div className="fvda-warning">
                    <Alert dmpStyle="warning">
                      FVDA content detected within CSV data. Check FVDA configuration below before running batch
                      operation.
                    </Alert>
                  </div>
                )}
              </BatchSetting>
            )}

            {useTasks && canPushToFilevine && (
              <BatchSetting title="Push to Filevine" className="fvda">
                <Checkbox
                  id="chk-push-to-filevine"
                  checked={pushToFilevine}
                  onChange={() => this.setState({ pushToFilevine: !pushToFilevine })}
                >
                  Push generated documents back to Filevine
                </Checkbox>
                <div className="template-download">
                  Documents will be placed in a timestamped folder in Docs inside your Filevine project.
                </div>
              </BatchSetting>
            )}
            {useTasks && canPushToFilevine && (
              <BatchSetting title="Select format(s)">
                {PUSH_TO_FILEVINE_FORMATS.map((format) => (
                  <Checkbox
                    key={`format-${format.key}`}
                    style={{ marginBottom: 10 }}
                    id={`chk-push-to-filevine-format-${format.key}`}
                    checked={pushToFilevineFormats.includes(format.key)}
                    onChange={() =>
                      this.setState({
                        pushToFilevineFormats: pushToFilevineFormats.includes(format.key)
                          ? _.without(pushToFilevineFormats, format.key)
                          : _.concat(pushToFilevineFormats, format.key),
                      })
                    }
                  >
                    {format.title}
                  </Checkbox>
                ))}
              </BatchSetting>
            )}

            {this.showCSV && user.isAdmin && (
              <BatchSetting title="Admin Options">
                <Switch
                  id="chk-over-limit"
                  checked={isOverLimit}
                  onChange={() => this.setState({ isOverLimit: !isOverLimit })}
                  style={{ marginTop: 10 }}
                  size="small"
                >
                  <span className="chk-over-limit-label">
                    Use <b>over limit</b> mode to import up to 10K {dt}s.
                  </span>
                </Switch>
                <Switch
                  id="chk-bypass-validation"
                  checked={bypassValidation}
                  onChange={() => this.setState({ bypassValidation: !bypassValidation })}
                  style={{ marginTop: 10 }}
                  size="small"
                >
                  <span className="chk-over-limit-label">Bypass Variable Validation</span>
                </Switch>
              </BatchSetting>
            )}

            {action === ACTIONS.UPLOAD.key && metas.length > 0 && (
              <BatchSetting title={`${Dt} Date`}>
                <>
                  {DATE_MODES.map((mode) => (
                    <label key={mode.key} className="date-mode">
                      <input
                        type="radio"
                        name="date-mode"
                        checked={dateMode === mode.key}
                        onChange={() => this.setState({ dateMode: mode.key })}
                        data-cy={`date-mode-${mode.key}-radio`}
                      />
                      <span>{mode.title}</span>
                    </label>
                  ))}
                </>
              </BatchSetting>
            )}

            {template && action === ACTIONS.CREATE.key && metas.length > 0 && (
              <BatchSetting title="Sharing">
                <>
                  {template.parties.map((party) => (
                    <Switch
                      id={`auto-party-${party.partyID}`}
                      key={party.partyID}
                      checked={parties.indexOf(party.partyID) > -1}
                      onChange={() => this.toggleParty(party.partyID)}
                      size="small"
                    >
                      {party.displayName}
                    </Switch>
                  ))}
                </>
              </BatchSetting>
            )}

            {parties.length > 0 && (
              <BatchSetting title="Sharing Mode">
                <>
                  {SHARING_MODES.map((mode) => (
                    <label key={mode.key} className="sharing-mode" data-cy="sharing-mode">
                      <input
                        type="radio"
                        name="sharing-mode"
                        checked={sharingMode === mode.key}
                        onChange={() => this.setState({ sharingMode: mode.key })}
                        data-cy={`sharing-mode-${mode.key}-radio`}
                      />
                      <span>{mode.title}</span>
                    </label>
                  ))}
                </>
              </BatchSetting>
            )}
          </Card>
        </div>
        {metas.length > 0 && this.renderMetadata()}
        {this.renderConfirmation()}

        {batchTaskConfirm && (
          <ModalConfirm
            show={true}
            onConfirm={() => this.setState({ batchTaskConfirm: false })}
            dmpStyle="default"
            confirmText="Close"
            title="Batch Operation Task"
            body="Your task has successfully been triggered!"
            info={
              <>
                It might take a while depending on the size of the batch operation. Once completed, you will receive an
                email with a link to download the results.
                <br />
                <br />
                You may close this window—the task will continue.
              </>
            }
          />
        )}

        {creatingTask && this.renderTaskProgress()}
      </div>
    );
  }

  renderMetadata() {
    const { user } = this.props;
    const { action, template, processing, done, simulate, metas, isOverLimit } = this.state;
    const connections = _.map(metas, 'connections');
    const cols = action && template ? getColumns(action, template, connections) : null;

    return (
      <div className="metadata" data-cy="metadata">
        <div className="meta-actions" data-cy="meta-actions">
          <div className="meta-info" data-cy="meta-info">
            <h4>
              <span>{Dt} &amp; Metadata Preview</span>
              <span className={cx('status', { done, ready: this.isReady, missing: this.missingPDFs > 0 })}>
                {this.operationStatus}
              </span>
            </h4>

            {this.action.metaInstructions && <div className="meta-instructions">{this.action.metaInstructions}</div>}

            {this.metaErrors.length > 0 && (
              <ul className="meta-errors">
                <li>The following errors in CSV data must be fixed before import:</li>
                {_.map(this.metaErrors, (msg, idx) => (
                  <li key={idx}>{msg}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="spacer" />

          {USE_LEGACY_BATCH_OPS && user.isAdmin && !done && (
            <Checkbox
              id="chk-simulate-batch"
              checked={simulate}
              onChange={() => this.setState({ simulate: !simulate })}
            >
              Simulate
            </Checkbox>
          )}
          {USE_LEGACY_BATCH_OPS && done && (
            <>
              <Button to="/dashboard/contracts" data-cy="btn-view-all">
                View all {dt}s
              </Button>
              <a ref={this.download} href={this.downloadLink} download={this.downloadFilename} />
            </>
          )}
          <Button
            disabled={!!processing || !this.isReady || done}
            dmpStyle="primary"
            onClick={this.processQueue}
            data-cy="btn-start"
          >
            {processing ? 'Processing...' : done ? 'Done' : 'Start'}
          </Button>
        </div>

        {!isOverLimit && (
          <Dropzone
            disabled={action !== ACTIONS.UPLOAD.key}
            accept={FILE_TYPES.PDF}
            activeClassName="uploader active"
            className="file-list"
            disableClick={!metas.length}
            onDropAccepted={this.onPDFAccepted}
            data-cy="file-list"
          >
            <DataTable
              data={metas}
              columns={cols}
              getTrProps={trProps}
              loading={false}
              minRows={1}
              dropshadow
              hasFixedColumns
            />
          </Dropzone>
        )}

        {isOverLimit && (
          <Dropzone
            disabled={action !== ACTIONS.UPLOAD.key}
            accept={FILE_TYPES.PDF}
            activeClassName="uploader active"
            className="file-list"
            disableClick={!metas.length}
            onDropAccepted={this.onPDFAccepted}
            data-cy="file-list"
          >
            <Card>
              <div className="align-items-center d-flex justify-content-center" data-cy="over-limit">
                {!done &&
                  (!processing ? (
                    <>
                      <Icon name="info" size="xlarge" />
                      <h4 style={{ marginLeft: 15 }}>Over Limit mode!</h4>
                    </>
                  ) : (
                    <>
                      <Loader size="large" />
                      <h4 style={{ marginLeft: 15 }}>Generating...</h4>
                    </>
                  ))}
                {!processing && done && (
                  <>
                    <Icon name="check" size="xlarge" />
                    <h4 style={{ marginLeft: 15 }}>Complete!</h4>
                  </>
                )}
              </div>
            </Card>
          </Dropzone>
        )}
      </div>
    );
  }

  render() {
    const { subscription, user } = this.props;
    if (!subscription) return null;

    if (user.can(FEATURES.BATCH)) {
      return this.renderAdmin();
    }

    return this.renderCustomer();
  }
}
