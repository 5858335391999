import React, { Component } from 'react';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import * as pdfjs from 'pdfjs-dist';
import PropTypes from 'prop-types';

import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import Deal from '@core/models/Deal';
import DealVersion from '@core/models/DealVersion';
import { ELEMENT_TYPE } from '@core/models/PDFElement';
import Party from '@core/models/Party';
import User from '@core/models/User';
import Variable from '@core/models/Variable';
import { MERGE_TYPE } from '@core/models/Version';
import { parsePDF, parseParagraphs } from '@core/parsing/PDF2Vine';
import { convertPixelsToPoints, convertPointsToPixels } from '@core/utils/Converters';
import { dt, getDevicePixelRatio } from '@core/utils/Environment';

import { Alert, Loader } from '@components/dmp';

import { PDF_REVIEW_DEFAULTS } from '@components/PDFReview';
import { PDFPage } from '@components/pdf-editor';
import Fire from '@root/Fire';

// That's what we used before we switched to "import * as pdfjs from 'pdfjs-dist/webpack';"
// Let's keep it until we're sure that the new way is solid.

// This makes things work again, plus it's a large lib (>1mb) so maybe just continue loading externally...
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.1.81/pdf.worker.min.js';

const ELEMENT_ZINDEX = 100;
const PDF_ERRORS = {
  DEFAULT: (
    <span>
      An error ocurred while trying to load the PDF.
      <br />
      Please try again or contact us.
    </span>
  ),
  NOT_FOUND: (
    <span>
      The PDF associated with this {dt} version could not be found.
      <br />
      Please try again or contact us.
    </span>
  ),
  PASSWORD: (
    <span>
      Outlaw does not yet support password-protected PDFs.
      <br />
      Please remove the password and upload the document again.
    </span>
  ),
};

// We're using 2 open-source libs here to accomplish "legacy" style eSigning
// pdfjslib enables visualization on HTML5 Canvas
// And pdf-lib (see PDFDeal) enables modification of the PDF file itself
// directly in the browser (no server required which is amazing)
@autoBindMethods
export default class PDFView extends Component {
  newElementKey = null;

  static defaultProps = {
    pdfEditMode: null,
    pdfField: null,
    onPDFEditMode: _.noop,
  };

  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.instanceOf(User),
    scale: PropTypes.number.isRequired,
    pdfEditMode: PropTypes.string,
    pdfField: PropTypes.oneOfType([PropTypes.instanceOf(Party), PropTypes.instanceOf(Variable)]),
    onPDFEditMode: PropTypes.func,
    onLoad: PropTypes.func.isRequired,
    selectedVersion: PropTypes.instanceOf(DealVersion),
    panel: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.devicePixelRatio = getDevicePixelRatio();

    this.state = {
      dataURL: null,
      errorMessage: '',
      isSaving: false,
      loading: true,
      pages: [],
      pdf: null,
      rendered: false,
      url: null,
      viewports: [],
      reviewOptions: _.cloneDeep(PDF_REVIEW_DEFAULTS),
    };

    this.pdfLoadingTask = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // This is helpful to block renders when we are adding a new element since it can
    // update a lot of things at the same time. Also, it's required for the focus() on create to work
    // if it becomes problematic, then we can find an other solutions.
    if (this.state.isSaving && nextState.isSaving) {
      return false;
    }

    return true;
  }

  // Initiate loading sequence immediately on component mounting
  componentDidMount() {
    this.loadPDF();

    document.addEventListener('copy', this.handleTextCopy);
  }

  componentWillUnmount() {
    document.removeEventListener('copy', this.handleTextCopy);
  }

  componentDidUpdate(prevProps) {
    const { scale, selectedVersion } = this.props;
    if (prevProps.scale !== scale) {
      this.refreshPDF();
    }
    // Reload new (or different pdf) when version changes,
    // i.e., if a version is added or deleted
    if (selectedVersion && selectedVersion.key !== _.get(prevProps.selectedVersion, 'key')) {
      console.log(`Loading deal version: [${selectedVersion.key}] (v${selectedVersion.ordinal})`);
      this.loadPDF();
    }
  }

  /*
    I attempted multiple times to format the content that was being copied to set
    the text color to black and remove the background, nothing worked well.

    The simplest solution I found was to temporarely change the styling right before and after
    it gets copied by the browser.
  */
  handleTextCopy() {
    document.querySelectorAll('.page-text-layer').forEach((node) => {
      node.classList.add('copying');
    });

    setTimeout(() => {
      document.querySelectorAll('.page-text-layer').forEach((node) => {
        node.classList.remove('copying');
      });
    }, 10);
  }

  get elements() {
    const { deal, scale, selectedVersion } = this.props;

    // Any current/pending changes (ie PDFElements) should only be visible on the latest version
    // So if we're viewing a previous version, return empty object so as to not overlay elements onto it
    if (selectedVersion !== deal.currentVersion) return {};

    const elements = _.map(deal.pdfElements, (element) => {
      const topPixels = convertPointsToPixels(element.y, scale);
      const leftPixels = convertPointsToPixels(element.x, scale);

      const isNew = this.newElementKey && this.newElementKey === element.key ? true : false;
      if (isNew) {
        // Make sure it only happen once since this can all re-render
        this.newElementKey = null;
      }

      return {
        value: element.displayValue,
        id: element.id,
        page: element.page,
        top: topPixels,
        left: leftPixels,
        width: convertPointsToPixels(element.width, scale),
        pdfElement: element,
        zIndex: ELEMENT_ZINDEX,
        isNew,
      };
    });

    return _.groupBy(_.keyBy(elements, 'id'), 'page');
  }

  // https://github.com/mozilla/pdf.js/blob/master/web/app.js#L550
  async reset() {
    await this.setState({ errorMessage: null });

    if (this.pdfLoadingTask) {
      await this.pdfLoadingTask.destroy();
      console.log('[PDF] Previous pdf loading task destroyed');
      this.pdfLoadingTask = null;
    }

    if (typeof PDFBug !== 'undefined') {
      console.log('[PDF] Bug encountered when resetting doc -- cleaning up resources');
      PDFBug.cleanup();
    }

    if (this.state.pdf) {
      await this.setState({ pdf: null });
      console.log('[PDF] Previous pdf document cleared from state');
    }
  }

  setReviewOptions(reviewOptions) {
    this.setState({ reviewOptions });
  }

  async loadPDF(ingest = false) {
    const { scale, onLoad, selectedVersion, deal, user } = this.props;
    const viewportScale = scale * this.devicePixelRatio;
    const pages = [];
    const viewports = [];
    let pageLenses = [];
    let url;
    let raw;

    await this.reset();

    try {
      // 1. Get the PDF file's url (in GCP bucket) based on Deal
      const pdfPath = selectedVersion.pdfBucketPath;
      url = await Fire.storage.ref(pdfPath).getDownloadURL();

      // https://firebase.google.com/docs/storage/web/file-metadata
      // TODO: may want to limit (not display) files over a certain size
      // we can fetch metadata before download/display via this call
      // const meta = await Fire.storage.ref(pdfPath).getMetadata();

      // 2. Download the raw PDF data as an ArrayBuffer
      raw = await axios.get(url, { responseType: 'arraybuffer' });
    } catch (err) {
      onLoad(null);
      this.setState({ errorMessage: PDF_ERRORS.NOT_FOUND });
      return;
    }

    // 3. Load the data into pdfjs (need to wrap in our own Promise because using await directly is deprecated, weirdly)
    // https://mozilla.github.io/pdf.js/examples/index.html#interactive-examples
    const pdf = await new Promise((resolve, reject) => {
      this.pdfLoadingTask = pdfjs.getDocument(raw);
      this.pdfLoadingTask.promise.then(resolve).catch((err) => {
        console.error(err);
        const errorKey = _.get(err, 'name', null) === 'PasswordException' ? 'PASSWORD' : 'DEFAULT';
        onLoad(null);
        this.setState({ errorMessage: PDF_ERRORS[errorKey] });
        reject();
      });
    });

    // 4. Store individual page-level data and viewports in state to prep for rendering
    if (pdf && pdf.numPages > 0) {
      const pagesText = [];
      for (var i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: viewportScale });
        pages.push(page);
        viewports.push(viewport);

        const pageText = await page.getTextContent();
        const [, , width, height] = page.view;
        pageText.width = width;
        pageText.height = height;
        pagesText.push(pageText);
      }

      //when explicitly called, save ingested data
      if (ingest) {
        const { pages: lenses, paragraphs, omitted } = parsePDF(pagesText);
        pageLenses = lenses;

        const { sections } = parseParagraphs(paragraphs);
        Fire.ingestDeal(deal, user, { sections, omitted }, MERGE_TYPE.OVERWRITE);
      }
    }

    // 5. Update state with all necessary data, which will trigger a re-render to get <canvas> elements into DOM
    await this.setState({ pdf, pages, pageLenses, viewports, url, loading: false });

    // 6. Communicate doc back to parent for updating related components
    onLoad(pdf);
  }

  async refreshPDF() {
    const { pdf } = this.state;
    const { scale } = this.props;
    const viewportScale = scale * this.devicePixelRatio;
    const pages = [];
    const viewports = [];

    // 4. Store individual page-level data and viewports in state to prep for rendering
    if (pdf && pdf.numPages > 0) {
      for (var i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const viewport = page.getViewport({ scale: viewportScale });
        pages.push(page);
        viewports.push(viewport);
      }
    }

    // 5. Update state with all necessary data, which will trigger a re-render to get <canvas> elements into DOM
    this.setState({ pages, viewports });
  }

  async handleAdd(elementType, params) {
    const { deal, onPDFEditMode, pdfField, scale } = this.props;

    if (elementType === 'pointer') return;

    await this.setState({ isSaving: true });

    params.x = convertPixelsToPoints(params.x, scale);
    params.y = convertPixelsToPoints(params.y, scale);

    // pdfField can be an instance of either a Party (for signatures, and eventually for initials(?))
    // or a Variable, for both variable fields and party properties (e.g., Company.org)
    if (pdfField) {
      if (elementType === ELEMENT_TYPE.SIGNATURE) {
        params.variable = pdfField.partyID;
      } else if (elementType === ELEMENT_TYPE.VARIABLE) {
        params.variable = pdfField.name;
      }
    }

    const newElement = await deal.addElement(elementType, params);
    this.newElementKey = _.get(newElement, 'key', null);
    onPDFEditMode(null);

    await this.setState({ isSaving: false });
  }

  async handleDragEnd({ id, page, y, x }) {
    const { deal, scale } = this.props;
    const element = _.find(deal.pdfElements, { id });

    if (!element) {
      console.error(`[handleDragEnd] Element [${id}] not found on Deal.`);
      return null;
    }

    element.x = convertPixelsToPoints(x, scale);
    element.y = convertPixelsToPoints(y, scale);

    // It's possible that the element changed page, that's why we always set it
    element.page = page;

    // Save to the DB
    await Fire.savePDFElement(element);
  }

  async handleResized(id, width) {
    const { deal, scale } = this.props;
    const element = _.find(deal.pdfElements, { id });

    if (!element) {
      console.error(`[handleResized] Element [${id}] not found on Deal.`);
      return null;
    }

    element.width = convertPixelsToPoints(width, scale);

    // Save to the DB
    await Fire.savePDFElement(element);
  }

  render() {
    const { errorMessage, pdf, loading, pages, viewports, reviewOptions } = this.state;
    const { pdfEditMode, scale, user, deal, team, panel } = this.props;

    if (errorMessage) {
      return (
        <div className="fullscreen-preloader d-flex align-content-center justify-content-center">
          <Alert dmpStyle="dark" title="PDF loading error" icon="nope">
            {errorMessage}
          </Alert>
        </div>
      );
    }

    if (!pdf || loading) {
      return (
        <div className="fullscreen-preloader">
          <Loader size="large" />
        </div>
      );
    }

    const elements = this.elements;

    return (
      <div className={cx('pdf-view', { 'panel-open': panel })}>
        <div className="wrapper-canvas">
          <DndProvider backend={Backend}>
            <div className="wrapper-pages">
              {_.map(viewports, (viewport, idx) => (
                <PDFPage
                  deal={deal}
                  team={team}
                  editMode={pdfEditMode}
                  user={user}
                  elements={elements[idx]}
                  scale={scale}
                  id={`Page-${idx}`}
                  key={idx}
                  onMoveElement={this.handleDragEnd}
                  onAddElement={this.handleAdd}
                  pageIndex={idx}
                  pageData={pages[idx]}
                  setElementWidth={this.handleResized}
                  viewport={viewport}
                  devicePixelRatio={this.devicePixelRatio}
                  reviewOptions={reviewOptions}
                />
              ))}
            </div>
          </DndProvider>
        </div>
      </div>
    );
  }
}
