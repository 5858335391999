import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormGroup } from 'react-bootstrap';

import DealRole from '@core/enums/DealRole';
import Teammate from '@core/models/Teammate';
import User from '@core/models/User';

import { Button, Loader } from '@components/dmp';

import TeammateBlock from '@components/deal/TeammateBlock';
import TeamSelector from '@components/teams/TeamSelector';
import TeammateSearch from '@components/teams/TeammateSearch';
import API from '@root/ApiClient';

@autoBindMethods
export default class TeammateSelector extends Component {
  static defaultProps = {
    displayLimit: 100,
    forceSearch: false,
    existingUsers: [],
  };

  static propTypes = {
    title: PropTypes.string,
    team: PropTypes.object,
    teams: PropTypes.array,
    user: PropTypes.instanceOf(User),
    onSelect: PropTypes.func,
    existingUsers: PropTypes.array,
    menuWidth: PropTypes.number,
    displayLimit: PropTypes.number,
    forceSearch: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      team: null,
      loading: false,
      teammates: [],
      filteredTeammates: [],
      adding: false,
      showTeammateFilter: false,
    };

    //Checkboxes require a unique ID; just generate one based on timestamp at creation to ensure uniqueness
    //because there may be multiple <TeammateSelector> components throughout app
    this.baseID = new Date().getTime().toString();
  }

  //we may or may not have teams passed in via props at component load time
  //so try to auto-load both initially and when component updates
  componentDidMount() {
    this.autoSelectTeam(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.autoSelectTeam(this.props);
  }

  get forceSearch() {
    const { forceSearch, displayLimit } = this.props;
    const { teammates, query, filteredTeammates } = this.state;

    return forceSearch || (!query && teammates.length > displayLimit);
  }

  autoSelectTeam(props) {
    //if there's already a team selected, ignore
    if (this.state.team || this.state.loading) return;

    //otherwise try to select and load team members in based on props
    let { team, teams } = props;
    if (!team && teams) team = teams[Object.keys(teams)[0]];
    if (team) this.selectTeam(team.teamID, true);
  }

  //expose state.adding so that an external async call can control via refs
  updateAdding(adding) {
    this.setState({ adding });
  }

  selectTeam(teamID, autoSelect) {
    const { displayLimit, team, teams } = this.props;

    const selectedTeam = autoSelect ? team : _.find(teams, { teamID });

    // Load in team members for that team
    this.setState({ team: selectedTeam, loading: true });
    API.call('getTeamMembers', { teamID: selectedTeam.teamID, excludeInvites: true }, (teammates) => {
      teammates = _.map(teammates, (teammate) => new Teammate(teammate));
      const newState = { teammates, loading: false };
      if (teammates.length > displayLimit) newState.showTeammateFilter = true;
      this.setState(newState);
    });
  }

  setFilteredTeammates(ids, query) {
    const results = this.state.teammates.filter((teammate) => ids.includes(teammate.id));
    this.setState({ filteredTeammates: results, query });
  }

  render() {
    const { title, teams, menuWidth, user } = this.props;
    const { teammates, showTeammateFilter, loading, team } = this.state;

    return (
      <div className="teammate-selector" data-cy="teammate-selector">
        {teams && teams.length > 0 && (
          <div className="team-selector" style={{ margin: '10px' }}>
            {title && <span className="name">{title}</span>}

            <FormGroup className="team-filter">
              {team && teams.length == 1 && (
                <div className="single-team" style={{ flex: 1 }}>
                  {team.info.name}
                </div>
              )}
              {teams.length > 1 && (
                <TeamSelector
                  disabled={loading}
                  size="small"
                  teamID={team?.teamID || null}
                  teams={teams}
                  onSelect={this.selectTeam}
                  clearable={false}
                  width={menuWidth}
                  user={user}
                />
              )}
              <Button
                onClick={() => this.setState({ showTeammateFilter: !showTeammateFilter })}
                iconOnly={true}
                icon="filterExpand"
                size="small"
                disabled={loading}
                data-cy="btn-filter-teammates"
              />
            </FormGroup>

            {showTeammateFilter && (
              <div>
                <TeammateSearch teammates={teammates} onApplyFilter={this.setFilteredTeammates} />
              </div>
            )}
          </div>
        )}
        <div className="scroll">
          <div className="teammates-list" data-cy="teammates-list">
            {loading && <Loader centered padding />}
            {!loading && this.renderTeammates()}
          </div>
        </div>
      </div>
    );
  }

  renderTeammates() {
    const { displayLimit, onSelect, existingUsers } = this.props;
    const { teammates, filteredTeammates, query } = this.state;

    let TeammatesComponent = (
      <div className="message">
        <div className="txt">No teammates.</div>
      </div>
    );
    let TooManyComponent = null;
    let teammatesToDisplay = teammates;

    if (filteredTeammates.length) {
      teammatesToDisplay = filteredTeammates;
    } else if (query && !filteredTeammates.length) {
      teammatesToDisplay = [];
      TeammatesComponent = (
        <div className="message">
          <div className="txt">
            No teammates found.
            <br />
            Please remove text from the search filter above or choose another team.
          </div>
        </div>
      );
    }

    if (teammatesToDisplay?.length) {
      if (teammatesToDisplay.length > displayLimit) {
        const extra = teammatesToDisplay.length - displayLimit;
        teammatesToDisplay = teammatesToDisplay.slice(0, displayLimit);
        TooManyComponent = (
          <div className="message">
            <div className="txt">
              Showing 100 of {extra} teammates
              <br />
              Please use the search filter above
              <br />
              to narrow your results.
            </div>
          </div>
        );
      }

      TeammatesComponent = teammatesToDisplay.map((teammate) => {
        const added = existingUsers.indexOf(teammate.id) > -1;
        const adding = this.state.adding === teammate.id;

        return (
          <TeammateBlock
            key={teammate.id}
            teammate={teammate}
            disabled={added || adding}
            disabledReason={added ? 'Added' : adding ? 'Adding...' : ''}
            onClick={() => !added && !adding && onSelect(teammate, DealRole.VIEWER)}
          />
        );
      });
    }

    return (
      <>
        {TeammatesComponent}
        {TooManyComponent}
      </>
    );
  }
}
